import { InvoiceStatus, Partner } from '@georges-tech/facturation-model-lib';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { $gnotify } from '@/shared/utils/notify';

import { getToken } from '../../settings/modules/Options/components/maltSettings/maltSettings.service';
import { FACTURATION_PAGE_SIZE } from '../facturation.constants';
import {
  getFormattedActiveFilters,
  getFormattedFiltersForServerAPI,
  getFormattedTemplateCustomizationInfo,
  getGlobalCounts,
} from '../facturation.model';
import {
  countInvoices,
  createClient,
  createCreditNoteDraftFromIndyInvoice,
  createDraftFromIndyInvoice,
  createInvoiceDraftFromQuotation,
  createQuotation,
  createQuotationDraftFromQuotation,
  createQuotationDraftFromQuotationDraft,
  createSoldeDraftFromQuotation,
  deleteClient,
  deleteDraft,
  deleteImportedInvoice,
  deleteProduct,
  deleteQuotation,
  deleteQuotationDraft,
  duplicateDraft,
  editQuotation,
  fetchUser,
  getDatePresets,
  getHeaderImageUrl,
  getInvoicesKpis,
  getProducts,
  listAllInvoices,
  listClients,
  listInvoiceDrafts,
  listPaidInvoices,
  listPendingInvoices,
  listQuotationDrafts,
  listQuotations,
  receiptInvoice,
  setInvoiceStatus,
  setQuotationStatus,
  updateClient,
  updateUserCustomizationInfo,
} from '../facturation.services';

const stateData = {
  isFacturationStoreLoaded: false,
  isInvoicePanelFullscreenOpen: false,
  clients: [],
  invoiceDrafts: [],
  quotationDrafts: [],
  quotations: [],
  products: [],
  user: undefined,
  allInvoices: [],
  paidInvoices: [],
  pendingInvoices: [],
  invoicesKpis: {},
  invoicesCounts: undefined,
  pagination: {
    invoices: {
      pending: 1,
      paid: 1,
    },
  },
  maltToken: undefined,
  isMaltTokenLoaded: false,
  isFiltersPanelOpened: false,
  isFiltersPanelOpenedMobile: false,
  /** Indicates if the data matching the current filter is being loaded*/
  isFilteredDataLoading: false,
  filters: { clientId: undefined, dates: [], search: undefined },
  datePresets: {
    fiscalYears: [],
  },
  hasActiveFilters: false,
  templateCustomInfo: {},
  templateCustomInfoBuffer: {},
};

export const mutations = {
  setFacturationStoreLoaded: (state, isFacturationStoreLoaded) => {
    state.isFacturationStoreLoaded = isFacturationStoreLoaded;
  },
  resetStateData(state) {
    Object.assign(state, stateData);
  },
  toggleInvoicePanelFullscreen: (state, isInvoicePanelFullscreenOpen) => {
    if (typeof isInvoicePanelFullscreenOpen === 'boolean') {
      state.isInvoicePanelFullscreenOpen = isInvoicePanelFullscreenOpen;
    } else {
      state.isInvoicePanelFullscreenOpen = !state.isInvoicePanelFullscreenOpen;
    }
  },
  setProducts: (state, products) => {
    state.products = products;
  },
  setClients: (state, clients) => {
    state.clients = clients;
  },
  addClient: (state, client) => {
    state.clients.push(client);
  },
  updateClient: (state, { clientId, client }) => {
    const clientIndex = state.clients.findIndex((c) => c.id === clientId);
    state.clients.splice(clientIndex, 1, client);
  },
  setInvoiceDrafts: (state, invoiceDrafts) => {
    state.invoiceDrafts = invoiceDrafts;
  },
  setQuotationDrafts: (state, quotationDrafts) => {
    state.quotationDrafts = quotationDrafts;
  },
  setQuotations: (state, quotations) => {
    state.quotations = quotations;
  },
  addQuotation(state, quotation) {
    state.quotations.push(quotation);
  },
  setUser: (state, user) => {
    state.user = pick(user, [
      'id',
      'externalId',
      'companyName',
      'legalForm',
      'siret',
      'capitalSocial',
      'address',
      'logoUrl',
      'vatId',
      'greffe',
      'departementImmatriculationRepertoireMetiers',
      'extraInformation',
      'shouldSendDocumentByEmailWithBcc',
    ]);
  },
  setTemplateCustomInfo: (state, templateCustomInfo) => {
    state.templateCustomInfo = templateCustomInfo;
  },
  setInvoiceCounts: (state, counts) => {
    state.invoicesCounts = counts;
  },
  setInvoicesKpis: (state, invoicesKpis) => {
    state.invoicesKpis = invoicesKpis;
  },
  setReceiptedInvoice: (state, { invoiceId, receiptedInvoice }) => {
    const invoiceIndexInPaidInvoices = state.paidInvoices.findIndex((invoice) => invoice.id === invoiceId);
    state.paidInvoices.splice(invoiceIndexInPaidInvoices, 1, receiptedInvoice);
    const invoiceIndexInAllInvoices = state.allInvoices.findIndex((invoice) => invoice.id === invoiceId);
    state.allInvoices.splice(invoiceIndexInAllInvoices, 1, receiptedInvoice);
  },
  setQuotationStatus: (state, { quotationId, status }) => {
    const quotationIndex = state.quotations.findIndex((quotation) => quotation.id === quotationId);

    if (quotationIndex < 0) {
      return;
    }

    state.quotations[quotationIndex].status = status;
  },
  setMaltToken: (state, maltToken) => {
    state.maltToken = maltToken;
    state.isMaltTokenLoaded = true;
  },
  deleteProduct: (state, { productId }) => {
    const productIndex = state.products.findIndex((product) => product.id === productId);
    if (productIndex < 0) {
      return;
    }
    state.products.splice(productIndex, 1);
  },
  deleteClient(state, { clientId }) {
    const clientIndex = state.clients.findIndex((client) => client.id === clientId);
    if (clientIndex < 0) {
      return;
    }

    state.clients.splice(clientIndex, 1);
  },
  deleteQuotationDraft(state, { quotationDraftId }) {
    const quotationDraftIndex = state.quotationDrafts.findIndex(
      (quotationDraft) => quotationDraft.id === quotationDraftId,
    );
    if (quotationDraftIndex < 0) {
      return;
    }

    state.quotationDrafts.splice(quotationDraftIndex, 1);
  },
  deleteQuotation(state, { quotationId }) {
    const quotationIndex = state.quotations.findIndex((quotation) => quotation.id === quotationId);
    if (quotationIndex < 0) {
      return;
    }

    state.quotations.splice(quotationIndex, 1);
  },
  addQuotationDraft(state, quotationDraft) {
    state.quotationDrafts.push(quotationDraft);
  },
  toggleFiltersPanel(state) {
    state.isFiltersPanelOpened = !state.isFiltersPanelOpened;
  },
  closeFiltersPanel(state) {
    state.isFiltersPanelOpened = false;
  },
  toggleFiltersPanelMobile(state) {
    state.isFiltersPanelOpenedMobile = !state.isFiltersPanelOpenedMobile;
  },
  closeFiltersPanelMobile(state) {
    state.isFiltersPanelOpenedMobile = false;
  },
  updateFilters(state, filters) {
    for (var key in filters) {
      state.filters[key] = filters[key];
    }
  },
  setFilteredDataLoadingStatus(state, loadingStatus) {
    state.isFilteredDataLoading = loadingStatus;
  },
  setDatePresets(state, datePresets) {
    state.datePresets = datePresets;
  },
  setHasActiveFilters(state) {
    if (isEqual(state.filters, stateData.filters)) {
      state.hasActiveFilters = false;
    } else {
      state.hasActiveFilters = true;
    }
  },
  updateTemplateCustomInfo(state, value) {
    state.templateCustomInfo = {
      ...state.templateCustomInfo,
      ...value,
    };
  },
  updateTemplateCustomInfoBuffer(state, value) {
    state.templateCustomInfoBuffer = {
      ...state.templateCustomInfoBuffer,
      ...value,
    };
  },
};

export const actions = {
  async fetchProducts({ commit }) {
    const { availableProducts } = await getProducts();

    commit('setProducts', availableProducts);
  },
  async fetchUser({ commit }) {
    const user = await fetchUser();

    const headerImageUrl = await getHeaderImageUrl();

    commit('setUser', user);
    commit('setTemplateCustomInfo', getFormattedTemplateCustomizationInfo({ ...user, headerImageUrl }));
  },
  async fetchClients({ commit }) {
    const clients = await listClients();

    commit('setClients', clients);
  },
  async fetchInvoiceDrafts({ commit, getters }) {
    const invoiceDrafts = await listInvoiceDrafts(getters.formattedFiltersForServerAPI);

    commit('setInvoiceDrafts', invoiceDrafts);
  },
  async fetchQuotationDrafts({ commit, getters }) {
    const quotationDrafts = await listQuotationDrafts(getters.formattedFiltersForServerAPI);

    commit('setQuotationDrafts', quotationDrafts);
  },
  async fetchQuotations({ commit, getters }) {
    const quotations = await listQuotations(getters.formattedFiltersForServerAPI);

    commit('setQuotations', quotations);
  },
  async countInvoices({ commit, getters }) {
    const counts = await countInvoices(getters.formattedFiltersForServerAPI);

    commit('setInvoiceCounts', counts);
  },
  async changePageByStatus({ state, dispatch }, { pageNumber, status }) {
    await dispatch('fetchInvoicesByStatus', {
      start: (pageNumber - 1) * FACTURATION_PAGE_SIZE,
      limit: FACTURATION_PAGE_SIZE,
      status,
    });
    state.pagination.invoices[status] = pageNumber;
  },
  async fetchAllInvoices({ state }) {
    state.allInvoices = await listAllInvoices();
  },
  async fetchPendingInvoices({ state, getters }, { start, limit }) {
    state.pendingInvoices = await listPendingInvoices({
      start,
      limit,
      filters: getters.formattedFiltersForServerAPI,
    });
  },
  async fetchPaidInvoices({ state, getters }, { start, limit }) {
    state.paidInvoices = await listPaidInvoices({
      start,
      limit,
      filters: getters.formattedFiltersForServerAPI,
    });
  },
  async fetchInvoicesByStatus({ dispatch }, { start, limit, status }) {
    if (status === InvoiceStatus.PAID) {
      await dispatch('fetchPaidInvoices', { start, limit });
    } else if (status === InvoiceStatus.PENDING) {
      await dispatch('fetchPendingInvoices', { start, limit });
    } else {
      console.error('Status is mandatory');
    }
  },
  async fetchInvoicesKpis({ commit, getters }) {
    const kpis = await getInvoicesKpis(getters.formattedFiltersForServerAPI);

    commit('setInvoicesKpis', kpis);
  },
  async fetchMaltToken({ commit }) {
    const maltToken = await getToken(Partner.MALT);

    commit('setMaltToken', maltToken);
  },
  async createClient({ commit }, client) {
    const createdClient = await createClient({ client });

    commit('addClient', createdClient);

    return createdClient;
  },
  async updateClient({ commit, dispatch }, { clientId, client }) {
    const updatedClient = await updateClient({ clientId, client });

    commit('updateClient', { clientId, client: updatedClient });
    dispatch('facturationClients/fetchClients', null, { root: true });

    return updatedClient;
  },
  async createQuotation({ commit }, { quotationDraftId, quotationNumber }) {
    const quotation = await createQuotation({
      quotationDraftId,
      quotationNumber,
    });

    commit('addQuotation', quotation);

    return quotation;
  },
  async refreshFacturationInvoices({ dispatch, commit, state }) {
    commit('setFacturationStoreLoaded', false);

    const promisesToAwait = [
      dispatch('fetchInvoiceDrafts'),
      dispatch('fetchAllInvoices'),
      dispatch('fetchPendingInvoices', {
        start: (state.pagination.invoices.pending - 1) * FACTURATION_PAGE_SIZE,
        limit: FACTURATION_PAGE_SIZE,
      }),
      dispatch('fetchPaidInvoices', {
        start: (state.pagination.invoices.paid - 1) * FACTURATION_PAGE_SIZE,
        limit: FACTURATION_PAGE_SIZE,
      }),
      dispatch('fetchInvoicesKpis'),
      dispatch('countInvoices'),
    ];

    await Promise.all(promisesToAwait);

    commit('setFacturationStoreLoaded', true);
  },
  async initializeFacturation({ dispatch, commit, state }) {
    commit('setFacturationStoreLoaded', false);

    const promisesToAwait = [
      dispatch('fetchClients'),
      dispatch('facturationClients/fetchClients', null, { root: true }),
      dispatch('fetchInvoiceDrafts'),
      dispatch('fetchUser'),
      dispatch('fetchProducts'),
      dispatch('fetchAllInvoices'),
      dispatch('fetchPendingInvoices', { start: 0, limit: FACTURATION_PAGE_SIZE }),
      dispatch('fetchPaidInvoices', { start: 0, limit: FACTURATION_PAGE_SIZE }),
      dispatch('fetchInvoicesKpis'),
      dispatch('fetchQuotationDrafts'),
      dispatch('fetchQuotations'),
      dispatch('countInvoices'),
      dispatch('getDatePresets'),
    ];

    await Promise.all(promisesToAwait);

    commit('setFacturationStoreLoaded', true);

    // Reset pagination
    state.pagination.invoices = {
      pending: 1,
      paid: 1,
    };
  },
  async deleteQuotationDraft({ commit }, quotationDraftId) {
    await deleteQuotationDraft({ quotationDraftId });
    commit('deleteQuotationDraft', { quotationDraftId });
  },
  async deleteDraft({ dispatch }, draftId) {
    await deleteDraft({ draftId });
    await dispatch('fetchInvoiceDrafts');
    await dispatch('fetchQuotations');
  },
  async deleteQuotation({ commit }, quotationId) {
    await deleteQuotation({ quotationId });
    commit('deleteQuotation', { quotationId });
  },
  async setInvoiceStatus({ dispatch }, { invoiceId, status }) {
    try {
      await setInvoiceStatus(invoiceId, status);
      await dispatch('refreshFacturationInvoices');
    } catch (e) {
      $gnotify.error(`Une erreur est survenue en changeant le status de la facture`);
    }
  },
  async setQuotationStatus({ state, commit }, { quotationId, status }) {
    const quotation = state.quotations.find((quotation) => quotation.id === quotationId);
    const quotationStatus = quotation?.status;

    commit('setQuotationStatus', { quotationId, status });

    try {
      await setQuotationStatus(quotationId, status);
    } catch (e) {
      $gnotify.error(`Une erreur est survenue en changeant le status du devis`);
      commit('setQuotationStatus', { quotationId, status: quotationStatus });
    }
  },
  async deleteClient({ commit }, clientId) {
    await deleteClient({ clientId });

    commit('deleteClient', { clientId });
  },
  async deleteProduct({ commit }, productId) {
    await deleteProduct({ productId });

    commit('deleteProduct', { productId });
  },
  async duplicateDraft({ dispatch }, draftId) {
    const duplicatedDraft = await duplicateDraft({ draftId });
    await dispatch('fetchInvoiceDrafts');
    return duplicatedDraft;
  },
  async createDraftFromIndyInvoice({ dispatch }, invoiceId) {
    const newDraft = await createDraftFromIndyInvoice({ invoiceId });
    await dispatch('fetchInvoiceDrafts');
    return newDraft;
  },
  async createSoldeDraftFromQuotation({ dispatch }, quotationId) {
    const newDraft = await createSoldeDraftFromQuotation({ quotationId });
    await dispatch('fetchInvoiceDrafts');
    return newDraft;
  },
  async createCreditNoteDraftFromIndyInvoice({ dispatch }, invoiceId) {
    const newDraft = await createCreditNoteDraftFromIndyInvoice({ invoiceId });
    await dispatch('fetchInvoiceDrafts');
    return newDraft;
  },
  async createInvoiceDraftFromQuotation({ dispatch }, quotationId) {
    const newInvoiceDraft = await createInvoiceDraftFromQuotation({ quotationId });
    await dispatch('fetchInvoiceDrafts');
    return newInvoiceDraft;
  },
  async createQuotationDraftFromQuotationDraft({ commit }, quotationDraftId) {
    const newQuotationDraft = await createQuotationDraftFromQuotationDraft({ quotationDraftId });

    commit('addQuotationDraft', newQuotationDraft);
    return newQuotationDraft;
  },
  async createQuotationDraftFromQuotation({ commit }, quotationId) {
    const newQuotationDraft = await createQuotationDraftFromQuotation({ quotationId });

    commit('addQuotationDraft', newQuotationDraft);
    return newQuotationDraft;
  },
  async editQuotation({ commit }, quotationId) {
    const quotationDraft = await editQuotation({ quotationId });
    commit('addQuotationDraft', quotationDraft);
    commit('deleteQuotation', { quotationId });
    return quotationDraft;
  },
  async deleteImportedInvoice({ dispatch }, invoiceId) {
    try {
      await deleteImportedInvoice({ invoiceId });
      $gnotify.success('La facture a bien été supprimée');
      await dispatch('refreshFacturationInvoices');
    } catch (error) {
      $gnotify.error(
        error.response?.data?.defaultMessage || 'Une erreur est survenue lors de la suppression de la facture',
      );
    }
  },
  async updateFilters({ commit, dispatch }, filters) {
    try {
      commit('setFilteredDataLoadingStatus', true);
      commit('updateFilters', filters);
      commit('setHasActiveFilters');
      await Promise.all([
        dispatch('refreshFacturationInvoices'),
        dispatch('fetchQuotationDrafts'),
        dispatch('fetchQuotations'),
        dispatch('facturationClients/fetchClients', null, { root: true }),
      ]);
    } finally {
      commit('setFilteredDataLoadingStatus', false);
    }
  },
  async resetFilters({ dispatch }) {
    await dispatch('updateFilters', stateData.filters);
  },
  async clearFilter({ dispatch }, key) {
    if (key === 'dates') {
      await dispatch('updateFilters', { [key]: [] });
    } else {
      await dispatch('updateFilters', { [key]: undefined });
    }
  },
  async getDatePresets({ commit }) {
    const datePresets = await getDatePresets();
    commit('setDatePresets', datePresets);
  },
  async receiptInvoice({ commit }, { invoiceId, receiptDate, paymentMethods, paymentReference, signature }) {
    try {
      const receiptedInvoice = await receiptInvoice({
        invoiceId,
        receiptDate,
        paymentMethods,
        paymentReference,
        signature,
      });
      commit('setReceiptedInvoice', { invoiceId, receiptedInvoice });
    } catch (e) {
      $gnotify.error(
        e.response?.data?.defaultMessage ??
          "Une erreur est survenue lors de l'acquittement de la facture. Veuillez contacter le support si le problème persiste",
      );
      throw e;
    }
  },
  async updateTemplateCustomInfo({ commit }, value) {
    try {
      await updateUserCustomizationInfo(value);
      commit('updateTemplateCustomInfo', value);
    } catch (e) {
      $gnotify.error(`Une erreur est survenue lors de l'enregistrement de vos paramètres de personnalisation`);
    }
  },
};

export const getters = {
  isInvoicePanelFullscreenOpen: ({ isInvoicePanelFullscreenOpen }) => isInvoicePanelFullscreenOpen,
  clients: ({ clients }) => clients,
  invoiceDrafts: ({ invoiceDrafts }) => invoiceDrafts,
  quotationDrafts: ({ quotationDrafts }) => quotationDrafts,
  quotations: ({ quotations }) => quotations,
  products: ({ products }) => products,
  user: ({ user }) => user,
  pendingInvoices: ({ pendingInvoices }) =>
    pendingInvoices.sort((invoice1, invoice2) => new Date(invoice2.date).getTime() - new Date(invoice1.date).getTime()),
  paidInvoices: ({ paidInvoices }) =>
    paidInvoices.sort((invoice1, invoice2) => new Date(invoice2.date).getTime() - new Date(invoice1.date).getTime()),
  // TODO : remove afer refacto to avoid asking big query to db
  allInvoices: (state) => state.allInvoices,
  invoiceById:
    ({ invoices }) =>
    (id) => {
      return invoices.find((invoice) => invoice.id === id);
    },
  invoicesKpis: ({ invoicesKpis }) => invoicesKpis,
  invoicesCounts: ({ invoicesCounts }) => invoicesCounts,
  maltToken: ({ maltToken }) => maltToken,
  isMaltTokenLoaded: ({ isMaltTokenLoaded }) => isMaltTokenLoaded,
  isFacturationStoreLoaded: ({ isFacturationStoreLoaded }) => isFacturationStoreLoaded,
  invoicesPagination: ({ pagination }) => pagination.invoices,
  isFiltersPanelOpened: ({ isFiltersPanelOpened }) => isFiltersPanelOpened,
  isFiltersPanelOpenedMobile: ({ isFiltersPanelOpenedMobile }) => isFiltersPanelOpenedMobile,
  getFormattedActiveFilters: ({ filters, clients }) => getFormattedActiveFilters(filters, clients),
  datePresets: ({ datePresets }) => datePresets,
  filters: ({ filters }) => filters,
  formattedFiltersForServerAPI: ({ filters }) => getFormattedFiltersForServerAPI(filters),
  globalCounts: (_state, { invoicesCounts, invoiceDrafts, quotations, quotationDrafts }, _rootState, rootGetters) => {
    return getGlobalCounts({
      invoicesCounts,
      invoiceDrafts,
      quotations,
      quotationDrafts,
      clientsCounts: rootGetters['facturationClients/totalClients'],
    });
  },
  templateCustomInfo: ({ templateCustomInfo }) => templateCustomInfo,
  templateCustomInfoBuffer: ({ templateCustomInfoBuffer }) => templateCustomInfoBuffer,
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  actions,
  getters,
};
