import { getClients } from '../facturation.services';

const stateData = {
  clients: [],
  totalClients: undefined,
  isLoading: false,
  isError: false,
  filters: {
    currentPage: 1,
  },
};

export const mutations = {
  setClients: (state, clients) => {
    state.clients = clients;
  },
  setTotalClients: (state, totalClients) => {
    state.totalClients = totalClients;
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setIsError: (state, isError) => {
    state.isError = isError;
  },
  setCurrentPage: (state, currentPage) => {
    state.filters.currentPage = currentPage;
  },
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async fetchClients({ commit, state, rootState }) {
    commit('setIsLoading', true);

    try {
      const { clients, totalClients } = await getClients({
        currentPage: state.filters.currentPage,
        ...(rootState.facturation.filters?.search && { search: rootState.facturation.filters.search }),
      });

      commit('setClients', clients);
      commit('setTotalClients', totalClients);
    } catch (e) {
      commit('setIsError', true);
    } finally {
      commit('setIsLoading', false);
    }
  },
  updateCurrentPage({ commit, dispatch }, currentPage) {
    commit('setCurrentPage', currentPage);
    dispatch('fetchClients');
  },
  resetCurrentPage({ commit, dispatch }) {
    commit('setCurrentPage', 1);
    dispatch('fetchClients');
  },
};

export const getters = {
  clients: ({ clients }) => clients,
  totalClients: ({ totalClients }) => totalClients,
  isLoading: ({ isLoading }) => isLoading,
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  actions,
  getters,
};
